import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FederationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { SafePipe } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  selector: 'cp-logout-iframe',
  imports: [CommonModule, SafePipe],
  templateUrl: './logout.component.html',
})
export class LogoutComponent {
  logoutUrl = '';

  constructor(private federationService: FederationService) {
    this.federationService.logoutUrl$.subscribe(url => {
      this.logoutUrl = url;
    });
  }

  logoutLoaded(): void {
    if (this.federationService.logoutProcessRunning) {
      // finish logout process, clean iframe
      this.federationService.finishLogout();
    }
  }
}
