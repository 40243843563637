import { Component } from '@angular/core';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

import { ErrorPageDefaultsDirective } from '../error-page-defaults.directive';

@Component({
  standalone: true,
  imports: [ErrorPageComponent],
  selector: 'cp-403-page',
  templateUrl: './page-forbidden.component.html',
  styleUrls: [],
})
export class PageForbiddenComponent extends ErrorPageDefaultsDirective {
  statusForbidden = $localize`403 Forbidden.`;
  statusTextForbidden = $localize`You are not allowed to access this resource.`;
  statusDescForbidden = $localize`Please contact your system administrator for access.`;
}
