<div>
  <div class="name">{{ $name() }}</div>
  <div class="street">{{ $street() }}</div>
  <div class="zip-city"
    >{{ $zip() }} {{ $city() }}
    {{ $countryName() ? "" : "(" + $countryCode() + ")" }}
  </div>
  @if ($countryName()) {
    <div class="country">{{ $countryName() }}</div>
  }
</div>
