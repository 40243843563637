import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  inject,
} from '@angular/core';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { FeedbackMessageService } from '@ev-portals/cp/frontend/shared/util';
import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  NotificationComponent,
  PromptDialogComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { DialogService } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  selector: 'cp-dynamic-content',
  imports: [
    CommonModule,
    PromptDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    NotificationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './dynamic-content.component.html',
})
export class DynamicContentComponent {
  authenticationService = inject(AuthenticationService);
  feedbackMessageService = inject(FeedbackMessageService);
  dialogService = inject(DialogService);
  changeDetectorRef = inject(ChangeDetectorRef);
  customRenderer = 0;

  constructor() {
    this.#listenToImpersonationMode();
    this.#listenToInternalUser();
    this.#listenToLogoutSuccess();
  }

  #listenToInternalUser(): void {
    this.authenticationService.user$.subscribe(user => {
      user?.isInternalUser
        ? this.feedbackMessageService.addInternalUserNotification()
        : this.feedbackMessageService.removeInternalUserNotification();
    });
  }

  #listenToImpersonationMode(): void {
    effect(
      () => {
        this.authenticationService.$impersonationMode()
          ? this.feedbackMessageService.addImpersonationNotification()
          : this.feedbackMessageService.removeImpersonationNotification();
      },
      { allowSignalWrites: true },
    );
  }

  #listenToLogoutSuccess(): void {
    this.authenticationService.logoutSuccess$.subscribe({
      next: () => {
        this.feedbackMessageService.showSuccessMessage(
          $localize`You have been successfully logged out.`,
        );
      },
      error: () => {
        this.feedbackMessageService.showErrorMessage();
      },
    });
  }
}
