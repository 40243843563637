import { Directive } from '@angular/core';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';

@Directive()
export class ErrorPageDefaultsDirective {
  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
  ) {}

  actionItems = [
    {
      name: 'Logout',
      key: 'logout',
    },
    {
      name: 'Contact Us',
      key: 'contact',
    },
  ];

  clickHandler(key: string): void {
    if (key === 'logout') {
      this.authenticationService.logout().subscribe(this.authenticationService.logoutHandler);
    } else if (key === 'contact') {
      this.navigationService.navigateToContact();
    } else {
      console.error('Key not recognised');
    }
  }
}
