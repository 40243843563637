import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  PatchToursDto,
  TourState,
  UserBasicInfoDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { LayoutService } from '@ev-portals/ev/frontend/util';
import { offset } from '@floating-ui/dom';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root',
})
export class CartTourService {
  #layoutService = inject(LayoutService);
  #authService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);

  tour = this.#getEmptyTour();

  constructor() {
    // Initialize the tour
    this.#authService.permissionMap$
      .pipe(
        map(permissionMap => permissionMap.uom_selection),
        takeUntilDestroyed(),
      )
      .subscribe(uomSelectionPermission => {
        this.tour = this.#getEmptyTour();
        this.#addSteps(uomSelectionPermission || false);
      });

    // Start automatically but only on the cart (/purchase) page
    const triggerSubscription = combineLatest([
      this.#authService.user$,
      this.#navigationService.activePageName$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([user, pageName]) => {
        // Start the tour, if: currently on the cart page, the cartTour is not finished yet, checkout permission is available
        if (
          pageName === 'cart' &&
          user &&
          !user.tours.cartTour &&
          this.#authService.$permissionMap()?.checkout
        ) {
          this.#syncTourState(user);
          this.startTour();
          triggerSubscription.unsubscribe();
        }
      });
  }

  #syncTourState(user: UserBasicInfoDto): void {
    this.tour.on('complete', () => {
      const tours = {
        ...user.tours,
        cartTour: TourState.Completed,
      } as PatchToursDto;
      this.#authService.patchTours(tours).subscribe();
    });

    this.tour.on('cancel', () => {
      const tours = {
        ...user.tours,
        cartTour: TourState.Cancelled,
      } as PatchToursDto;
      this.#authService.patchTours(tours).subscribe();
    });
  }

  async startTour(): Promise<boolean> {
    const isDesktopView = await firstValueFrom(this.#layoutService.isDesktopView$);

    if (isDesktopView) {
      this.#navigationService.navigateToCart();
      this.tour.start();
    }

    return isDesktopView;
  }

  #addSteps(uomSelectionPermission: boolean): void {
    this.tour.addStep({
      id: 'locations',
      canClickTarget: false,
      beforeShowPromise: () => this.#untilElementFound('.tour-locations'),
      text: $localize`Line items shown in this Shopping Cart are specific to the selected SoldTo, ShipTo, BillTo, and Payer locations.`,
      attachTo: {
        element: '.tour-locations',
        on: 'bottom',
      },
    });

    this.tour.addStep({
      id: 'editAllItemsLink',
      canClickTarget: false,
      text: () => $localize`Click here to edit all line items in the Shopping Cart at once.`,
      attachTo: {
        element: '.tour-edit-all-items-link',
        on: 'bottom',
      },
      when: {
        // Click the link
        hide: () => (document.querySelector('.tour-edit-all-items-link') as HTMLElement).click(),
      },
    });

    this.tour.addStep({
      id: 'editAllPon',
      canClickTarget: false,
      text: () =>
        $localize`If you want to assign same Purchase Order Number to all line items enter here and click [Apply] button`,
      beforeShowPromise: () => this.#untilElementFound('.tour-edit-all-pon'),
      attachTo: {
        element: '.tour-edit-all-pon',
        on: 'bottom',
      },
    });

    this.tour.addStep({
      id: 'editAllRdd',
      canClickTarget: false,
      text: () =>
        $localize`If you want to assign same Request Delivery Date to all line items enter here and click [Apply] button`,
      attachTo: {
        element: '.tour-edit-all-rdd',
        on: 'bottom',
      },
      when: {
        // Close the dialog and open the cart options dropdown
        hide: () => {
          (document.querySelector('.tour-close-edit-all-dialog') as HTMLElement).click();
          (document.querySelector('.tour-cart-options-link') as HTMLElement).click();
        },
      },
    });

    this.tour.addStep({
      id: 'saveCart',
      beforeShowPromise: () => this.#untilElementFound('.tour-save-cart-button'),
      canClickTarget: false,
      text: () => $localize`You can save the current cart as a template for later use`,
      attachTo: {
        element: '.tour-save-cart-button',
        on: 'bottom',
      },
    });
    this.tour.addStep({
      id: 'clearCart',
      canClickTarget: false,
      text: () => $localize`You can delete all the line Items of the current cart`,
      attachTo: {
        element: '.tour-clear-cart-button',
        on: 'bottom',
      },
    });
    this.tour.addStep({
      id: 'downloadCart',
      canClickTarget: false,
      text: () =>
        $localize`You can download the current Cart as excel template, which you can later use for uploading the cart`,
      attachTo: {
        element: '.tour-download-cart-button',
        on: 'bottom',
      },
    });
    this.tour.addStep({
      id: 'uploadCart',
      canClickTarget: false,
      text: () =>
        $localize`You can use the downloaded excel file to append new line items or overwrite current cart with the line items in excel template`,
      attachTo: {
        element: '.tour-upload-cart-button',
        on: 'bottom',
      },
      when: {
        // Close the dropdown and open the first accordion
        hide: async () => {
          (document.querySelector('.tour-cart-options-link') as HTMLElement).click();
          // Ensure, that the line-item-group accordion is open and wait 100ms until continue
          (document.querySelector('.tour-line-item-group-opener') as HTMLElement).click();

          await new Promise(resolve => setTimeout(resolve, 100));

          if (!document.querySelector('.tour-pon')?.checkVisibility()) {
            (document.querySelector('.tour-show-item-details-link') as HTMLElement).click();
          }
        },
      },
    });

    this.tour.addStep({
      beforeShowPromise: () => this.#untilElementFound('.tour-quantity'),
      id: 'quantity',
      canClickTarget: false,
      text: () =>
        uomSelectionPermission
          ? $localize`You can adjust the quantity using the unit of measure you prefer. It will provide you with the correct quantity based on your input.`
          : $localize`You can modify the quantity of the given unit of measure. It will provide you with the correct quantity based on your input.`,
      attachTo: {
        element: '.tour-quantity',
        on: 'bottom',
      },
    });

    this.tour.addStep({
      id: 'rdd',
      canClickTarget: false,
      text: () =>
        $localize`The Request Delivery Date is a mandatory field, and you might need to adjust it based on business rules`,
      attachTo: {
        element: '.tour-rdd',
        on: 'bottom',
      },
    });

    this.tour.addStep({
      id: 'pon',
      canClickTarget: false,
      text: () => $localize`The Purchase Order Number is a mandatory field`,
      attachTo: {
        element: '.tour-pon',
        on: 'bottom',
      },
    });

    this.tour.addStep({
      id: 'poin',
      canClickTarget: false,
      text: () => $localize`The Purchase Order Item Number is a mandatory field.`,
      attachTo: {
        element: '.tour-poin',
        on: 'bottom',
      },
    });
    this.tour.addStep({
      id: 'delete',
      canClickTarget: false,
      text: () => $localize`By clicking the Delete button, you can delete individual line items`,
      attachTo: {
        element: '.tour-delete',
        on: 'bottom',
      },
    });
    this.tour.addStep({
      id: 'calculateButton',
      showOn: () => !!document.querySelector('.tour-calculate-button'),
      canClickTarget: false,
      text: () =>
        $localize`Once mandatory fields are entered, you need to click "Calculate prices" if everything is valid, Checkout button will be enabled`,
      attachTo: {
        element: '.tour-calculate-button',
        on: 'bottom',
      },
    });
  }

  #untilElementFound(queryTerm: string): Promise<void> {
    return new Promise<void>(resolve => {
      const interval = setInterval(() => {
        if (document.querySelector(queryTerm)) {
          resolve();
          clearInterval(interval);
        }
      }, 100);
    });
  }

  #showMenuItemsAndWaitUntil(queryTerm: string): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        if (document.querySelector(queryTerm)) {
          resolve();
        } else {
          this.#showMenuItemsAndWaitUntil(queryTerm).then(() => resolve());
        }
      }, 100);
    });
  }

  #getEmptyTour(): Shepherd.Tour {
    return new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: '',
        scrollTo: true,
        floatingUIOptions: {
          middleware: [offset(15)],
        },
        modalOverlayOpeningPadding: 5,
        cancelIcon: {
          enabled: true,
        },
        buttons: [
          {
            text: $localize`Next`,
            action: () => {
              this.tour.next();
            },
          },
        ],
      },
    });
  }
}

export interface TourEvent {
  index: number;
  tour: Shepherd.Tour;
}
