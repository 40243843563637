import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  PatchToursDto,
  TourState,
  UserBasicInfoDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { Environment, NavigationService, PageName } from '@ev-portals/cp/frontend/shared/util';
import { LayoutService } from '@ev-portals/ev/frontend/util';
import { offset } from '@floating-ui/dom';
import { firstValueFrom, Subscription } from 'rxjs';
import Shepherd from 'shepherd.js';

import { TourEvent } from './cart-tour.service';

@Injectable({
  providedIn: 'root',
})
export class IntroTourService {
  #layoutService = inject(LayoutService);
  #authService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);
  #destroyRef = inject(DestroyRef);
  #environment = inject(Environment);

  $hasSalesRole = toSignal(this.#authService.hasSalesRole$);

  tour = this.#getEmptyTour();

  // This should be overwritten by the init method
  #showProfileMenuItems = (): void => {
    return console.error('showProfileMenuItems not implemented');
  };

  #hideProfileMenuItems = (): void => {
    return console.error('hideProfileMenuItems not implemented');
  };

  init(showProfileMenuItems: () => void, hideProfileMenuItems: () => void): void {
    // Initialize the tour
    this.tour = this.#getEmptyTour();
    this.#addSteps();
    this.#hideProfileMenuOnFinish();

    // Setup callback method
    this.#showProfileMenuItems = showProfileMenuItems;
    this.#hideProfileMenuItems = hideProfileMenuItems;

    // Start the tour automatically if needed
    let navSubscription: Subscription | null = null;
    this.#authService.user$.subscribe(user => {
      // Unsubscribe from the previous subscription
      navSubscription?.unsubscribe();

      if (!user) {
        return;
      }

      navSubscription = this.#navigationService.activePageName$.subscribe(pageName => {
        // Start the tour, if: currently on the cart page, the cartTour is not finished yet, checkout permission is available
        if (
          pageName &&
          (['home', 'product-list'] as PageName[]).includes(pageName) &&
          !user?.tours.introductionTour
        ) {
          this.#syncTourState(user);
          this.startTour();
        }
      });
    });
  }

  #hideProfileMenuOnFinish(): void {
    this.tour.on('complete', () => {
      this.#hideProfileMenuItems();
    });
    this.tour.on('cancel', () => {
      this.#hideProfileMenuItems();
    });
  }

  #syncTourState(user: UserBasicInfoDto): void {
    this.tour.on('complete', (event: TourEvent) => {
      const tours = {
        ...user.tours,
        introductionTour: TourState.Completed,
      } as PatchToursDto;
      this.#authService.patchTours(tours).subscribe();
    });

    this.tour.on('cancel', (event: TourEvent) => {
      const tours = {
        ...user.tours,
        introductionTour: TourState.Cancelled,
      } as PatchToursDto;
      this.#authService.patchTours(tours).subscribe();
    });
  }

  async startTour(): Promise<boolean> {
    const isDesktopView = await firstValueFrom(this.#layoutService.isDesktopView$);

    if (isDesktopView) {
      this.tour.start();
    }

    return isDesktopView;
  }

  #addSteps(): void {
    this.#authService.isLoggedIn$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(isLoggedIn => {
        this.tour.steps = [];
        this.tour.addStep({
          id: 'products',
          title: $localize`Products`,
          text: $localize`On our products page you can browse our available product portfolio.`,
          attachTo: {
            element: '.products-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'requests',
          title: $localize`Requests`,
          text: $localize`You can utilize the Requests section to submit inquiries regarding products or any questions related to products.`,
          attachTo: {
            element: '.requests-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'events',
          title: $localize`Events`,
          text: $localize`Stay informed about upcoming webinars and events hosted or attented by BASF.`,
          attachTo: {
            element: '.events-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'explore',
          showOn: () => !this.#environment.production,
          title: $localize`Explore`,
          text: () => $localize`Find the perfect thickening component for your formulation.`,
          attachTo: {
            element: '.explore-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'language',
          title: () => $localize`Language`,
          text: () =>
            $localize`Select your preferred language to customize your experience. This will help us present content and instructions in the language you're most comfortable with.`,
          attachTo: {
            element: '.language-menu-link',
            on: 'bottom',
          },
          buttons: isLoggedIn
            ? [
                {
                  text: () => $localize`Next`,
                  action: this.tour.next,
                },
              ]
            : [
                {
                  text: () => $localize`Finish`,
                  action: this.tour.complete,
                },
              ],
        });

        this.tour.addStep({
          id: 'cart',
          showOn: () => isLoggedIn && !!this.#authService.$permissionMap()?.checkout,
          title: () => $localize`Cart`,
          text: () =>
            $localize`Review your items and proceed to checkout. Make sure everything in your cart is correct before completing your purchase.`,
          attachTo: {
            element: '.cart-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'openProfile',
          title: () => $localize`Open your Profile Menu`,
          showOn: () => isLoggedIn,
          text: () =>
            $localize`Access your profile, settings, and user related pages such as Order History or Delivery Documents here.`,
          attachTo: {
            element: '.open-profile-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'profile',
          title: () => $localize`My Profile`,
          showOn: () => isLoggedIn,
          beforeShowPromise: () => this.#showMenuItemsAndWaitUntil('.profile-menu-link'),
          text: () => {
            return this.$hasSalesRole()
              ? $localize`Access information pertaining to your accounts, such as your current location for placing orders and the data stored for your user profile.`
              : $localize`Access information of your profile and user related information.`;
          },
          attachTo: {
            element: '.profile-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'order-history',
          title: () => $localize`Order History`,
          showOn: () =>
            isLoggedIn &&
            !!this.$hasSalesRole() &&
            !!this.#authService.$permissionMap()?.order_history,
          text: () =>
            $localize`Retrieve information regarding previously placed orders, including order details and their current status`,
          attachTo: {
            element: () => '.order-history-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'delivery-documents',
          showOn: () =>
            isLoggedIn &&
            !!this.$hasSalesRole() &&
            !!this.#authService.$permissionMap()?.order_history,
          title: () => $localize`Delivery Documents`,
          text: () => $localize`Here you can search for the delivery documents of your orders.`,
          attachTo: {
            element: () => '.delivery-documents-menu-link',
            on: 'bottom',
          },
        });

        this.tour.addStep({
          id: 'request-history',
          title: () => $localize`Request History`,
          showOn: () => isLoggedIn,
          text: () =>
            $localize`View details and status updates of inquiries and document requests that you have submitted through myPerformanceChemicals.`,
          attachTo: {
            element: () => '.request-history-menu-link',
            on: 'bottom',
          },
          buttons: [
            {
              text: () => (this.$hasSalesRole() ? $localize`Next` : $localize`Finish`),
              action: this.tour.next,
            },
          ],
        });

        this.tour.addStep({
          id: 'saved-carts',
          title: () => $localize`Saved Carts`,
          showOn: () =>
            isLoggedIn && !!this.$hasSalesRole() && !!this.#authService.$permissionMap()?.checkout,
          text: () =>
            $localize`Activate and edit your Saved Carts that can be used for quicker order placement.`,
          attachTo: {
            element: '.saved-carts-menu-link',
            on: 'bottom',
          },
          buttons: [
            {
              text: () => $localize`Finish`,
              action: this.tour.next,
            },
          ],
        });
      });
  }

  #showMenuItemsAndWaitUntil(queryTerm: string): Promise<void> {
    this.#showProfileMenuItems();
    return new Promise<void>(resolve => {
      setTimeout(() => {
        if (document.querySelector(queryTerm)) {
          resolve();
        } else {
          this.#showMenuItemsAndWaitUntil(queryTerm).then(() => resolve());
        }
      }, 100);
    });
  }

  #getEmptyTour(): Shepherd.Tour {
    return new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: '',
        scrollTo: true,
        floatingUIOptions: {
          middleware: [offset(15)],
        },
        modalOverlayOpeningPadding: 5,
        cancelIcon: {
          enabled: true,
        },
        buttons: [
          {
            text: $localize`Next`,
            action: () => {
              this.tour.next();
            },
          },
        ],
      },
    });
  }
}
