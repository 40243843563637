import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

@Component({
  standalone: true,
  imports: [ErrorPageComponent],
  selector: 'cp-404-page',
  templateUrl: './page-not-found.component.html',
  styleUrls: [],
})
export class PageNotFoundComponent {
  statusNotFound = $localize`404 Page not found.`;
  statusTextNotFound = $localize`Sorry, this page does not exist.`;
  statusDescriptionNotFound = $localize`The page might have been removed, had its name changed or is temporarily unavailable.`;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  actionItems = [
    {
      name: $localize`Home`,
      key: 'home',
    },
    {
      name: $localize`Contact Us`,
      key: 'contact',
    },
  ];

  clickHandler(key: string): void {
    if (key === 'home') {
      this.navigationService.navigateToHome();
    } else if (key === 'contact') {
      this.navigationService.navigateToContact();
    } else {
      console.error('Key not recognised');
    }
  }
}
