import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'cp-location-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './location-item.component.html',
  styleUrls: ['./location-item.component.scss'],
})
export class LocationItemComponent {
  $name = input.required<string>();
  $street = input.required<string>();
  $zip = input.required<string>();
  $city = input.required<string>();
  $countryCode = input.required<string>();
  // countryName can be undefined (used in order history details page)
  $countryName = input<string>();
}
