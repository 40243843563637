import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import {
  AccountAddress,
  AccountAddressItem,
  SelectedLocationDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { AddressType } from '@ev-portals/cp/frontend/shared/auth/util';
import {
  LoadingOverlayComponent,
  ModalDialogComponent,
  ShimmerEffectDirective,
} from '@ev-portals/ev/frontend/ui-library';

@Component({
  selector: 'cp-location-selection-dialog',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ReactiveFormsModule,
    ModalDialogComponent,
    ShimmerEffectDirective,
    LoadingOverlayComponent,
  ],
  templateUrl: './location-selection-dialog.component.html',
  styleUrls: ['./location-selection-dialog.component.scss'],
})
export class LocationSelectionDialogComponent {
  $title = input<string>();
  $subtitle = input<string>();
  $addressType = input<AddressType>();
  $userAddresses = input<AccountAddressItem[]>();
  $currentlySelectedLocation = input<SelectedLocationDto | null | undefined>();
  $hideCloseButton = input<boolean>(false);

  closeDialog = output<void>();
  addressSelection = output<SelectedAddress>();

  public $loading = signal<boolean>(true);

  $currentlySelectedAddressType = computed(() => {
    const currentlySelectedLocation = this.$currentlySelectedLocation();
    const addressType = this.$addressType();

    if (currentlySelectedLocation && addressType) {
      return currentlySelectedLocation[addressType];
    }

    return null;
  });

  emptyUserAddresses: AccountAddressItem[] = emptyArrayPlaceholder;

  $addressesToDisplay = computed(() => {
    return this.$userAddresses() ?? this.emptyUserAddresses;
  });

  constructor() {
    effect(
      () => {
        const userAddresses = this.$userAddresses();

        if (userAddresses) {
          if (userAddresses.length === 1) {
            // Automatically select the only address if there is only one (but continue showing the loading spinner)
            this.groupControl.setValue(userAddresses[0].key);
            this.onSubmit();
          } else if (userAddresses.length > 1) {
            this.$loading.set(false);
          }
        }
      },
      { allowSignalWrites: true },
    );
  }

  public groupControl = new FormControl<string>('', Validators.required);
  public onSubmit(): void {
    const { value } = this.groupControl;

    if (!value) return;

    const selectedAddress = {
      address: this.$userAddresses()?.find(address => address.key === value) as AccountAddressItem,
      addressType: this.$addressType() as AddressType,
    };
    this.addressSelection.emit(selectedAddress);
    this.$loading.set(true);
    this.groupControl.reset();
  }

  onClose(): void {
    this.closeDialog.emit();
  }

  formatAddress(address: AccountAddress): string {
    const { street = '', zip = '', city = '' } = address;
    return [street, zip, city].filter(val => !!val).join(' ');
  }
}

export interface SelectedAddress {
  address: AccountAddressItem;
  addressType: AddressType;
}

const emptyArrayPlaceholder = new Array(10).fill({
  key: '',
  name: '',
  address: {
    street: '',
    zip: '',
    city: '',
    countryCode: '',
    countryName: '',
  },
});
