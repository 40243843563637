import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

@Component({
  standalone: true,
  imports: [ErrorPageComponent, AngularModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './no-role-page.component.html',
  styleUrls: [],
})
export class NoRolePageComponent {
  private readonly navigationService = inject(NavigationService);

  readonly statusNoRole = $localize`Access denied.`;
  readonly statusTextNoRole = $localize`Currently, you don't have any access rights assigned to your account.`;
  readonly statusDescNoRole = $localize`In case, you have already registered, please make sure you have clicked the verification link in the mail you received from us. If the issue persists, please use our Contact Us page to send more detailed information.`;

  readonly productGroupSelectPlaceholder = $localize`Product Group`;
  readonly selectedProductGroup = new FormControl<string | undefined>(
    undefined,
    Validators.required,
  );

  readonly productGroups: { link: string; label: string }[] = [
    {
      label: 'Plastic Additives',
      link: 'https://plastics-rubber.basf.com/global/en/plastic_additives/registration.html',
    },
    {
      label: 'Oilfield Chemicals',
      link: 'https://energy-resources.basf.com/global/en/oilfield-chemicals/portal-registration.html',
    },
    {
      label: 'Mining Solutions',
      link: 'https://energy-resources.basf.com/global/en/mining_solutions/portal-registration.html',
    },
    {
      label: 'Lubricant Components',
      link: 'https://automotive-transportation.basf.com/global/en/fuel-and-lubricants/fuel-and-lubricant-solutions/components-for-lubricants/customer-portal-registration.html',
    },
    {
      label: 'GLYSANTIN®',
      link: 'https://www.glysantin.com/global/en/product-finder/where-to-buy/Portal-Registration.html',
    },
    {
      label: 'OPPANOL®',
      link: 'https://automotive-transportation.basf.com/global/en/fuel-and-lubricants/fuel-and-lubricant-solutions/polyisobutenes--pib-/oppanol_more-than-just-polyisobutene/Portal_Registration.html',
    },
  ];

  readonly actionItems = [
    {
      name: $localize`Contact Us`,
      key: 'contact',
    },
  ];

  openRegistrationLink() {
    if (this.selectedProductGroup.invalid) {
      return;
    }

    window.open(this.selectedProductGroup.value!, '_blank')?.focus();
  }

  clickHandler(key: string): void {
    if (key === 'contact') {
      this.navigationService.navigateToContact();
    } else {
      console.error('Key not recognised');
    }
  }
}
