<ev-modal-dialog
  [$title]="$title()"
  [$subtitle]="$subtitle()"
  [$hideCloseButton]="$hideCloseButton()"
  (closeDialog)="onClose()">
  @if ($loading()) {
    <ev-loading-overlay></ev-loading-overlay>
  }

  <form (ngSubmit)="onSubmit()">
    <div class="scroll">
      <atom-radio-group
        [evShimmerEffect]="$loading()"
        name="addressNames"
        label=""
        atomsControl
        [formControl]="groupControl">
        @for (item of $addressesToDisplay(); track item) {
          <atom-radio-button
            [evShimmerEffect]="$loading()"
            data-cy="location-select-radio-button"
            class="ev-row"
            [label]="
              item?.name +
              ' (' +
              item?.key +
              ') ' +
              (item?.key === $currentlySelectedAddressType() ? '✅' : '')
            "
            [helpText]="formatAddress(item.address)"
            [choiceValue]="item.key">
          </atom-radio-button>
        }
      </atom-radio-group>
    </div>
    <atom-button-submit
      [evShimmerEffect]="$loading()"
      fluid
      [disabled]="groupControl.invalid"
      (click)="onSubmit()"
      data-cy="location-select-button"
      ><span i18n>Select</span>
    </atom-button-submit>
  </form>
</ev-modal-dialog>
