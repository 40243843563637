<ev-error-page
  [status]="statusNoRole"
  [statusText]="statusTextNoRole"
  [statusDescription]="statusDescNoRole"
  [actionItems]="actionItems"
  (actionItemClick)="clickHandler($event)">
  <div class="flex flex-col mt-4 gap-1">
    <div class="flex flex-row text-xl gap-1">
      <atom-icon class="my-auto" iconId="atom:basf:link_circle"></atom-icon>
      <span class="my-auto" i18n
        >Get access to myPerformanceChemicals by registering for one of the
        Product Groups</span
      >
    </div>
    <div class="flex flex-row gap-2">
      <atoms-dropdown
        [formControl]="selectedProductGroup"
        class="w-80"
        [placeholder]="productGroupSelectPlaceholder"
        atomsControl2>
        @for (productGroup of productGroups; track productGroup) {
          <atoms-option [value]="productGroup.link">
            {{ productGroup.label }}
          </atoms-option>
        }
      </atoms-dropdown>

      <atom-button-submit
        class="mt-auto"
        [disabled]="selectedProductGroup.invalid"
        (click)="openRegistrationLink()">
        <span i18n>Register</span>
      </atom-button-submit>
    </div>
  </div>
</ev-error-page>
