import { Component } from '@angular/core';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

import { ErrorPageDefaultsDirective } from '../error-page-defaults.directive';

@Component({
  standalone: true,
  imports: [ErrorPageComponent],
  templateUrl: './page-forbidden-internal-login.component.html',
  styleUrls: [],
})
export class PageForbiddenInternalLoginComponent extends ErrorPageDefaultsDirective {
  statusForbidden = $localize`403 Forbidden.`;
  statusTextForbidden = $localize`Internal login is not available on production.`;
  statusDescForbidden =
    `<a class="ev-link no-restyle" href="https://qa.myperformancechemicals.basf.com" target="_blank">` +
    $localize`Please use our Test System for internal login` +
    `</a>`;
}
