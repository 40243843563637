import { CommonModule } from '@angular/common';
import { Component, forwardRef, inject, input } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { AtomsFormFieldModule } from '@atoms/angular-components';
import { AccountAddressItem } from '@ev-portals/cp/frontend/shared/api-client';
import { SelectedLocationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { DropdownModule } from 'primeng/dropdown';
import { map, of, switchMap, tap } from 'rxjs';

type LocationChangeFn = (location?: AccountAddressItem) => void;

@Component({
  selector: 'cp-ship-to-dropdown',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, AtomsFormFieldModule],
  templateUrl: './ship-to-dropdown.component.html',
  styleUrl: './ship-to-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShipToDropdownComponent),
      multi: true,
    },
  ],
})
export class ShipToDropdownComponent implements ControlValueAccessor {
  private readonly selectedLocationService = inject(SelectedLocationService);

  private onChange?: LocationChangeFn;
  private onTouched?: LocationChangeFn;

  readonly shipToTitle = $localize`ShipTo Address`;
  readonly soldToKey = input<string | undefined>();

  readonly location = new FormControl<AccountAddressItem | undefined>(undefined);

  readonly options$ = toObservable(this.soldToKey).pipe(
    switchMap(soldToKey => {
      if (!soldToKey) return of([{ value: undefined, name: $localize`All Partners` }]);

      return this.selectedLocationService.getAvailableShipTosFromBackend({ soldToKey }).pipe(
        map(locations => {
          return [
            { value: undefined, name: $localize`All Partners` },
            ...locations.items.map(location => ({
              value: location,
              name: `${location.name} | ${location.address.street}, ${location.address.zip} ${location.address.city} - ${location.address.countryCode}`,
            })),
          ];
        }),
      );
    }),
    tap(locations => this.location.setValue(locations[0].value)),
  );

  constructor() {
    this.location.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(location => this.onChange?.(location as AccountAddressItem | undefined));
  }

  writeValue(location?: AccountAddressItem): void {
    this.location.setValue(location, { emitEvent: false });
  }

  registerOnChange(fn: LocationChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: LocationChangeFn): void {
    this.onTouched = fn;
  }
}
