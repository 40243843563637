@if (options$ | async; as locations) {
  <div class="flex flex-col w-full">
    <label>{{ shipToTitle }}</label>
    <p-dropdown
      styleClass="flex items-center w-full"
      [options]="locations"
      [formControl]="location"
      optionLabel="name"
      optionValue="value"
      data-cy="ship-to-options"></p-dropdown>
  </div>
}
