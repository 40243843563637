import { CommonModule } from '@angular/common';
import { Component, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { AtomsFormFieldModule } from '@atoms/angular-components';
import { AccountAddressItem } from '@ev-portals/cp/frontend/shared/api-client';
import { SelectedLocationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { DropdownModule } from 'primeng/dropdown';
import { map, Observable } from 'rxjs';

type LocationChangeFn = (location?: Location) => void;

@Component({
  selector: 'cp-sold-to-dropdown',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, AtomsFormFieldModule],
  templateUrl: './sold-to-dropdown.component.html',
  styleUrl: './sold-to-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoldToDropdownComponent),
      multi: true,
    },
  ],
})
export class SoldToDropdownComponent implements ControlValueAccessor {
  private readonly selectedLocationService = inject(SelectedLocationService);

  private onChange?: LocationChangeFn;
  private onTouched?: LocationChangeFn;

  readonly soldToTitle = $localize`SoldTo Address`;

  readonly location = new FormControl<Location | undefined>(undefined);

  public readonly options$: Observable<SoldToOptions[]> =
    this.selectedLocationService.availableSoldToAddresses$.pipe(
      map(availableSoldTos => {
        const defaultOption = { value: undefined, name: $localize`All Partners` };

        const availableOptions: SoldToOptions[] = [
          defaultOption,
          ...availableSoldTos.items.map(location => ({
            value: location,
            name: `${location.name} | ${location.address.street}, ${location.address.zip} ${location.address.city} - ${location.address.countryCode}`,
          })),
        ];

        return availableOptions;
      }),
    );

  constructor() {
    this.location.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(location => this.onChange?.(location as Location | undefined));
  }

  writeValue(location?: Location): void {
    this.location.setValue(location, { emitEvent: false });
  }

  registerOnChange(fn: LocationChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: LocationChangeFn): void {
    this.onTouched = fn;
  }
}

interface SoldToOptions {
  value: AccountAddressItem | undefined;
  name: string;
}
