@if (userAddressesWithType$ | async; as userAddressesWithType) {
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
    @for (item of userAddressesWithType; track item) {
      <div>
        <label class="font-semibold capitalize-first-letter">
          {{ item.addressType }}
        </label>
        <label>({{ item.addressInfo.key }})</label>
        <cp-location-item
          [$name]="item.addressInfo.name"
          [$street]="item.addressInfo.address.street"
          [$zip]="item.addressInfo.address.zip"
          [$city]="item.addressInfo.address.city"
          [$countryCode]="item.addressInfo.address.countryCode"
          [$countryName]="item.addressInfo.address.countryName">
        </cp-location-item>
      </div>
    }
  </div>
} @else {
  <atom-progress-spinner></atom-progress-spinner>
}
