<div class="flex flex-col wrapper py-16 bg-gray-600 text-white">
  <div class="flex flex-row gap-8 items-center">
    <span class="text-xl" i18n>Follow us</span>
    <a href="https://www.linkedin.com/company/basf" target="_blank">
      <i class="ai-linkedin text-xl"></i>
    </a>
  </div>

  <div
    class="grid grid-cols-4 max-xl:grid-cols-2 max-md:grid-cols-1 gap-4 max-xl:gap-12 mt-8">
    @for (section of sections; track $index) {
      <div class="flex flex-col gap-4">
        <span class="text-xl">{{ section.title }}</span>
        @for (item of section.items; track $index) {
          <a
            class="flex flex-row gap-2 items-center cursor-pointer"
            [href]="item.link">
            <i
              class="ai-link-circle-outline text-xs"
              [attr.data-cy]="item.dataCy"></i>
            <span>{{ item.title }}</span>
          </a>
        }
      </div>
    }
  </div>

  <div class="my-10">
    <div>
      MyPerformanceChemicals: {{ appVersion }}
      @if (stage !== "prod") {
        | fe: {{ gitHash }} | api:
        {{ apiHash$ | async }}
      }
    </div>

    <span i18n>Copyright</span> © BASF SE {{ currentYear }}
  </div>

  <div class="flex flex-row flex-wrap gap-5">
    @for (item of bottomLinks; track $index) {
      <a
        [attr.href]="item.link"
        [attr.target]="item.target"
        [class]="[
          item.class,
          'flex',
          'flex-row',
          'gap-2',
          'items-center',
          'cursor-pointer',
        ]"
        [attr.data-cy]="item.dataCy">
        <i class="ai-link-circle-outline text-xs"></i>
        <span>{{ item.title }}</span>
      </a>
    }
  </div>
</div>
